import React, { useState, useEffect } from "react"
import { Helmet } from "react-helmet"
import { useAuth0 } from "@auth0/auth0-react"
import { Link } from "gatsby"
import { useContext } from "react"

// components
import { Header, Footer, BottomNavbar } from "../components/index"
import SubTitle from "../components/Common/SubTitle/SubTitle"
import Container from "../components/Common/Container/Container"

// utils
import { updateUserPlan } from "../utils/requests"
import { getPlan } from "../utils/helper"

// Context
import UserContext from "../context/UserAuth"

const Success = ({ location }) => {
  const { user, isAuthenticated, isLoading } = useAuth0() || {
    user: undefined,
    isAuthenticated: false,
    isLoading: false,
  }
  const { userMetaData, setUserMetaData } = useContext(UserContext) || {
    userMetaData: null,
    setUserMetaData: null,
  }

  useEffect(() => {
    if (user && isAuthenticated) {
      let plan = getPlan(location.search)
      updateUserPlan(user.sub, plan)
        .then(res => {
          console.log(res)
          setUserMetaData({
            ...userMetaData,
            app_metadata: {
              ...userMetaData.app_metadata,
              plan: plan,
            },
          })
        })
        .catch(err => {
          alert("error occured")
          console.error(err)
        })
    }
  }, [user])

  return (
    <>
      <Helmet>
        <title>Esteekey - Account Page</title>
        <meta name="description" content="Esteekey - #1 Fitness app for 2022" />
        <meta name="theme-color" content="#ffffff" data-react-helmet="true" />
      </Helmet>
      <Header />
      <div className="min-h-[calc(100vh-158px)] pt-[69px] xl:pt-0">
        <div className="my-16">
          <h3>You have successfully purchased a plan</h3>
          <Link to="/account">
            <button className="mt-4 bg-primary text-white">
              Go to Profile
            </button>
          </Link>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Success
